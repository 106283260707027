import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { MatDialogModule } from '@angular/material/dialog';
import { EnquiryDialog } from './dialogs/enquiry.dialog';

@NgModule({
  declarations: [
    AppComponent,
    EnquiryDialog
  ],
  entryComponents: [EnquiryDialog],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgxGoogleAnalyticsModule.forRoot('AW-587866689'),
    NgxGoogleAnalyticsRouterModule,
    MatDialogModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
