import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./features/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'showroom',
    loadChildren: () =>
      import('./features/buy/buy.module').then(m => m.BuyModule)
  },
  {
    path: 'showroom/view/:name',
    loadChildren: () =>
      import('./features/buy/buy.module').then(m => m.BuyModule)
  },
  {
    path: 'trade-sell-car',
    loadChildren: () =>
      import('./features/sell/sell.module').then(m => m.SellModule)
  },
  {
    path: 'services',
    loadChildren: () =>
      import('./features/services/services.module').then(m => m.ServicesModule)
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./features/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/siteinfo/siteinfo.module').then(m => m.SiteinfoModule)
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
