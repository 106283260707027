import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* import { FilterArrayPipe } from './data/filter-array.pipe'; */

import { MatButtonModule } from '@angular/material/button';
/* import { MatToolbarModule } from '@angular/material/toolbar'; */
/* import { MatMenuModule } from '@angular/material/menu'; */
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
/* import { MatSidenavModule } from '@angular/material/sidenav'; */
import { MatCheckboxModule } from '@angular/material/checkbox';
/* import { MatCardModule } from '@angular/material/card'; */
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
/* import { MatTooltipModule } from '@angular/material/tooltip'; */
/* import { MatSnackBarModule } from '@angular/material/snack-bar'; */
/* import { MatSlideToggleModule } from '@angular/material/slide-toggle'; */
/* import { MatDividerModule } from '@angular/material/divider'; */
/* import { MatSliderModule } from '@angular/material/'; */
/* import { MatDatepickerModule } from '@angular/material/datepicker'; */
/* import { MatNativeDateModule } from '@angular/material'; */
import { MatExpansionModule } from '@angular/material/expansion';
/* import { MatAutocompleteModule } from '@angular/material/autocomplete'; */
/* import { MatTableModule } from '@angular/material'; */
import { MatGridListModule } from '@angular/material/grid-list';
/* import { MatPaginatorModule } from '@angular/material/paginator'; */
import { NgxPaginationModule } from 'ngx-pagination';

/* import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material'; */

/* import { OverlayModule } from '@angular/cdk/overlay' */

/* import { GalleryModule } from  '@ngx-gallery/core'; */
import { NgxGalleryModule } from 'ngx-gallery-9';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    MatButtonModule,
    /* MatToolbarModule, */
    MatSelectModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    /* MatCardModule, */
    /* MatSidenavModule, */
    MatCheckboxModule,
    MatListModule,
    /* MatMenuModule, */
    MatIconModule,
    /* MatTooltipModule, */
    /* MatSnackBarModule, */
    /* MatSlideToggleModule, */
    /* MatDividerModule, */
    MatGridListModule,
    /* MatPaginatorModule, */
    NgxPaginationModule,

    NgxGalleryModule

    /* NgxDaterangepickerMd.forRoot(), */

    /* OverlayModule, */

    /* FontAwesomeModule */
  ],
  entryComponents: [
  ],
  declarations: [
    /* FilterArrayPipe */
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    /* FilterArrayPipe, */

    MatButtonModule,
    /* MatMenuModule, */
    MatTabsModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    /* MatCardModule, */
    /* MatSidenavModule, */
    MatListModule,
    MatSelectModule,
    /* MatToolbarModule, */
    MatIconModule,
    /* MatTooltipModule, */
    /* MatSnackBarModule, */
    /* MatSlideToggleModule, */
    /* MatDividerModule, */
    /* MatSliderModule, */
    /* MatDatepickerModule, */
    /* MatNativeDateModule, */
    MatExpansionModule,
    /* MatAutocompleteModule, */
    /* MatTableModule, */
    /* GalleryModule, */
    MatGridListModule,
    /* MatPaginatorModule, */
    NgxPaginationModule,

    NgxGalleryModule

    /* NgxDaterangepickerMd, */

    /* FontAwesomeModule */
  ]
})
export class SharedModule {}
