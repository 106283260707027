import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor (private http: HttpClient) {}

  private emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();

  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  apiUrl = 'https://solo-motors-app.uc.r.appspot.com';
  apiVehicles = '/vehicles/website/v1/list';
  apiMeta = '/vehicles/lists';
  apiEnquiries = '/enquiries'
  CACHE_SIZE = 1;

  private vehiclesCache$: Observable<Object>;
  private vehiclesMetaCache$: Observable<Object>;

  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    })
  };

  getVehicles() {
    if ( !this.vehiclesCache$ ) {
      this.vehiclesCache$ = this.vehiclesApi().pipe(shareReplay(this.CACHE_SIZE));
    }

    return this.vehiclesCache$;
  }

  getVehiclesMeta() {
    if ( !this.vehiclesMetaCache$ ) {
      this.vehiclesMetaCache$ = this.vehiclesMetaApi().pipe(shareReplay(this.CACHE_SIZE));
    }

    return this.vehiclesMetaCache$;
  }

  private vehiclesApi() {
    let query = encodeURI(`${this.apiUrl}${this.apiVehicles}`);
    console.log(query);

    return this.http.get(query, this.headers);
  }

  private vehiclesMetaApi() {
    let query = encodeURI(`${this.apiUrl}${this.apiMeta}`);
    console.log(query);

    return this.http.get(query, this.headers);
  }

  public resetVehiclesCache() {
    this.vehiclesCache$ = null;
  }

  public resetvehiclesMetaCache() {
    this.vehiclesMetaCache$ = null;
  }

  postEnquiry(data:object) {
    let query = encodeURI(`${this.apiUrl}${this.apiEnquiries}`);
    console.log(query);
    console.log(data);

    return this.http.post(query, data, this.headers);
  }

}
