import { Component, Inject, OnInit/* , ViewChild, ElementRef, ChangeDetectorRef */  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '../app/data/data.service';

@Component({
  selector: 'solo-motors-dialog-enquiry',
  templateUrl: './enquiry.dialog.html',
  styleUrls: ['./enquiry.dialog.scss'],
})

export class EnquiryDialog implements OnInit {
  processing: boolean;
  locations: any[];
  dialogSettings = {
    type: '',
    title: '',
  };
  vehicle_details: '';
  recaptchaValid: boolean;
  enquiryForm: FormGroup;
  /* financePreApprovalForm: FormGroup; */
  serviceMaintenanceEnquiryForm: FormGroup;
  carCleaningEnquiryForm: FormGroup;
  applyAsDealerEnquiryForm: FormGroup;
  statusMessage: any;
  errorMessage: any;
  successMessage: any;

  /* @ViewChild('recaptchaElement', {static: false }) recaptchaElement: ElementRef; */

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    /* private changeDetectorRef: ChangeDetectorRef, */
    private sanitizer: DomSanitizer,
    private DataService: DataService,
    private formBuilder: FormBuilder,
  ) {
    this.dialogSettings.type = data.type;
    this.dialogSettings.title = data.title;

    if (data.vehicle_details) {
      this.vehicle_details = data.vehicle_details;
    }
  }

  ngOnInit() {
    this.processing = false;
    this.recaptchaValid = true;

    this.locations = [
      'Eastern Cape',
      'Free State',
      'Gauteng',
      'KwaZulu-Natal',
      'Limpopo',
      'Mpumalanga',
      'Northern Cape',
      'North West',
      'Western Cape'
    ];

    this.statusMessage = '';

    if (this.dialogSettings.type == 'vehicle-enquiry') {

      this.successMessage = '<h3>Thank you for your enquiry</h3> <p>Your message has been successfully sent. A member of our team will be in contact with you as soon as possible.</p>';
      this.errorMessage = '<h3>Error sending enquiry</h3> <p>An error occured and your enquiry could not be sent. Please try again or contact us if the issue persists.</p>';

      this.enquiryForm = this.formBuilder.group({
        enquiry_type_id: [9],
        platform: ['web'],
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        contact_number: ['', [Validators.required, Validators.pattern("[0-9]{3}[0-9]{7}")]],
        email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
        extra_data: this.formBuilder.array([ this.createExtraDataVehicleEnquiry() ])
      }, { updateOn: 'change' });

      console.log(this.vehicle_details);

      /* setTimeout(() => {
        this.addRecaptchaScript();
      }); */

    } else if (this.dialogSettings.type == 'vehicle-finance-pre-approval') {

      /* this.successMessage = '<h3>Thank you for your enquiry</h3> <p>Your message has been successfully sent.</p>';
      this.errorMessage = '<h3>Error with pre-approval check</h3> <p>An error occured and your pre-approval check could not be sent. Please try again or contact us if the issue persists.</p>';

      this.financePreApprovalForm = this.formBuilder.group({
        enquiry_type_id: [1],
        platform: ['web'],
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        contact_number: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
        extra_data: this.formBuilder.array([ this.createExtraDataFinancePreApproval() ])
      }, { updateOn: 'change' }); */

    } else if (this.dialogSettings.type == 'service-maintenance-enquiry') {

      this.successMessage = '<h3>Thank you for your enquiry</h3> <p>Your message has been successfully sent. A member of our team will be in contact with you as soon as possible.</p>';
      this.errorMessage = '<h3>Error sending enquiry</h3> <p>An error occured and your enquiry could not be sent. Please try again or contact us if the issue persists.</p>';

      this.serviceMaintenanceEnquiryForm = this.formBuilder.group({
        enquiry_type_id: [11],
        platform: ['web'],
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        contact_number: ['', [Validators.required, Validators.pattern("[0-9]{3}[0-9]{7}")]],
        email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
        comments: ['', Validators.required],
        extra_data: this.formBuilder.array([ this.createExtraDataVehicleGeneralEnquiry() ])
      }, { updateOn: 'change' });

      /* setTimeout(() => {
        this.addRecaptchaScript();
      }); */

    } else if (this.dialogSettings.type == 'car-cleaning-enquiry') {

      this.successMessage = '<h3>Thank you for your enquiry</h3> <p>Your message has been successfully sent. A member of our team will be in contact with you as soon as possible.</p>';
      this.errorMessage = '<h3>Error sending enquiry</h3> <p>An error occured and your enquiry could not be sent. Please try again or contact us if the issue persists.</p>';

      this.carCleaningEnquiryForm = this.formBuilder.group({
        enquiry_type_id: [12],
        platform: ['web'],
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        contact_number: ['', [Validators.required, Validators.pattern("[0-9]{3}[0-9]{7}")]],
        email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
        comments: ['', Validators.required],
        extra_data: this.formBuilder.array([ this.createExtraDataVehicleGeneralEnquiry() ])
      }, { updateOn: 'change' });

      /* setTimeout(() => {
        this.addRecaptchaScript();
      }); */

    } else if (this.dialogSettings.type == 'apply-as-dealer-enquiry') {

      this.successMessage = '<h3>Thank you for your enquiry</h3> <p>Your message has been successfully sent. A member of our team will be in contact with you as soon as possible.</p>';
      this.errorMessage = '<h3>Error sending enquiry</h3> <p>An error occured and your enquiry could not be sent. Please try again or contact us if the issue persists.</p>';

      this.applyAsDealerEnquiryForm = this.formBuilder.group({
        enquiry_type_id: [13],
        platform: ['web'],
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        contact_number: ['', [Validators.required, Validators.pattern("[0-9]{3}[0-9]{7}")]],
        email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
        comments: ['', Validators.required],
        extra_data: this.formBuilder.array([ this.createExtraDataGeneralEnquiry() ])
      }, { updateOn: 'change' });

      /* setTimeout(() => {
        this.addRecaptchaScript();
      }); */

    }

  }

  createExtraDataVehicleEnquiry(): FormGroup {
    return this.formBuilder.group({
      'vehicle_details': [this.vehicle_details],
      'location': ['', Validators.required],
      'trading': [''],
      'apply_for_finance': [''],
    });
  }

  createExtraDataFinancePreApproval(): FormGroup {
    return this.formBuilder.group({
      'id_number': ['', Validators.required],
      'location': ['', Validators.required],
      'gross_pay': ['', Validators.required],
      'validation_check': ['', Validators.required],
      'terms_and_conditions': ['', Validators.requiredTrue],
    });
  }

  createExtraDataGeneralEnquiry(): FormGroup {
    return this.formBuilder.group({
      'location': ['', Validators.required]
    });
  }

  createExtraDataVehicleGeneralEnquiry(): FormGroup {
    return this.formBuilder.group({
      'location': ['', Validators.required],
      'vehicle_type': ['', Validators.required],
    });
  }

  /* renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey': '6Ld7MbsZAAAAAGhtcBSwaDg7Ab_pheX4FNk-1sGT',
      'callback': (response) => {
        if (response) {
          this.recaptchaValid = true;
          this.changeDetectorRef.detectChanges()
        }
      }
    });

    this.scaleCaptcha();
    window.onresize = () => { this.scaleCaptcha() };
  } */

  /* addRecaptchaScript() {
    if (window['grecaptcha']) {
      this.renderReCaptcha();
    } else {
      window['grecaptchaCallback'] = () => {
        this.renderReCaptcha();
      }

      (function(d, s, id, obj){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'recaptcha-jssdk', this));
    }
  } */

  honeyPot($event) {
    //console.log($event.target.value);
    this.recaptchaValid = false;
  }

  onSubmit(form: FormGroup) {
    console.log('Data', form.value);

    if (form.valid) {
      setTimeout(() => {
        this.DataService.emitChange(this.processing = true);
      }, 0);

      if (form.value['extra_data']) {
        form.value['extra_data'] = form.value['extra_data'][0];
      }

      this.DataService.postEnquiry(form.value).subscribe(response => {
        console.log(response);
        if (response) {
          this.statusMessage = this.sanitizer.bypassSecurityTrustHtml(this.successMessage);
        } else {
          this.statusMessage = this.sanitizer.bypassSecurityTrustHtml(this.errorMessage);
        }

        setTimeout(() => {
          this.DataService.emitChange(this.processing = false);
        }, 0);
      },
      err => {
        this.statusMessage = this.sanitizer.bypassSecurityTrustHtml(this.errorMessage);

        setTimeout(() => {
          this.DataService.emitChange(this.processing = false);
        }, 0);
      });

    }

  }

  /* scaleCaptcha() {
    var reCaptchaWidth = 304;
    var containerWidth = this.recaptchaElement.nativeElement.clientWidth;

    if((reCaptchaWidth > containerWidth) && window.innerWidth < 431) {
      var captchaScale = (containerWidth / reCaptchaWidth);
      this.recaptchaElement.nativeElement.style.transform = 'scale('+captchaScale+')';
    } else {
      this.recaptchaElement.nativeElement.style.transform = '';
    }
  } */

}
