import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EnquiryDialog } from '../dialogs/enquiry.dialog';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

import { ScrollDispatcher } from '@angular/cdk/scrolling';

import { DataService } from './data/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Solo Motors';
  loading = false;
  menuOpen = false;
  subscription: Subscription;
  scrolling: boolean;

  constructor(
    private router: Router,
    private DataService: DataService,
    public dialog: MatDialog,
    private scrollDispatcher: ScrollDispatcher
  ) {
    DataService.changeEmitted$.subscribe(loading => {
      this.loading = loading;
    });

    /* this.subscription = this.scrollDispatcher.scrolled().subscribe(x => {
      this.scrolling = true;

      //this.subscription.unsubscribe();
    }); */

    this.router.events.subscribe((event: Event) => {
      this.scrolling = false;

      if(event instanceof NavigationEnd) {
      }

      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    if (typeof window !== "undefined") {
      window.onresize = () => { this.menuOpen = false; };

      window.onload = function() {
        Array.from(document.querySelectorAll('.material-icons')).map((item: any) => {
          item.classList.add('display');
        })
      };
    }
  }

  menuToggle($event) {
    $event.preventDefault();

    this.menuOpen = !this.menuOpen;
  }

  onActivate($event) {
    if (typeof window !== "undefined" && !this.scrolling) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  openModal($event, type) {
    $event.preventDefault();
    let dialogRef;
    let previousScrollY;

    if (typeof window !== "undefined") {
      previousScrollY = window.scrollY;

      if (window.innerWidth < 769) {
        window.scrollTo(0, 0);
      }
    }

    if (type == 'financingPreApproval') {

      dialogRef = this.dialog.open(EnquiryDialog, {
        width: '470px',
        data: {
          type: 'vehicle-finance-pre-approval',
          title: 'Vehicle Finance pre-approval'
        }
      });

    }

    if (typeof window !== "undefined") {
      if (window.innerWidth < 769) {
        dialogRef.afterClosed().subscribe(result => {
          window.scrollTo({
            top: previousScrollY,
            left: 0,
          });
        });
      }
    }
  }

}
